import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    // header = (
    //   <h1
    //     style={{
    //       ...scale(1.5),
    //       marginBottom: rhythm(1.5),
    //       marginTop: 0,
    //       maxWidth: '960px',
    //       margin: 'auto'
    //     }}
    //   >
    //     <Link
    //       style={{
    //         boxShadow: `none`,
    //         color: `inherit`,
    //       }}
    //       to={`/`}
    //     >
    //       {title}
    //     </Link>
    //   </h1>
    // )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`
      }}
    >
      <header>{header}</header>
      <main style={{display: 'flex'}}>{children}</main>
      {/* <footer style={{maxWidth: '960px', margin: 'auto'}}>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer> */}
    </div>
  )
}

export default Layout
